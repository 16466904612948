import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import VueCookies from 'vue-cookies'
import store from './store'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueCookies)

// Vue.prototype.$devVersion = "Verze 1.00-20211214 Copyright © 2021 MTJ Service s.r.o."

Vue.prototype.$devVersion = "Verze 1.01-20230106 Copyright © 2021 MTJ Service s.r.o."
// Novy.vue upraveno -  //  ||  this.loggedFirmaID == "ENVItech Bohemia s.r.o."
// pro možnost editovat nové požadavky jako uživatel z Envitechu
// + úprava/oprava rules formuláře

Vue.prototype.$myServerPath = "https://eb-servis.cz"
// Vue.prototype.$myServerPath = "https://mytestwww.tode.cz/Envitech"
Vue.prototype.$dialog_msg_color = "rgb(603, 650, 200)"
Vue.prototype.$msg_color = "#8cc43f"
Vue.prototype.$warning_msg_color = "rgb(255, 153, 0)"

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
