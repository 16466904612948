import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Info from '../components/Info.vue'
import Prehled from '../components/Prehled.vue'
import Novy from '../components/Novy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/prehled',
    name: 'Prehled',
    component: Prehled
  },
  {
    path: '/novy',
    name: 'Novy',
    component: Novy
  },
]

const router = new VueRouter({
  routes
})

export default router
