<template>
  <div class="form" v-if="loggedUser != ''">
    <v-form
      ref="form"
      v-model="valid"
      :disabled="formDisabled"
      lazy-validation
      class="pa-2"
    >
      <v-row
        no-gutters
        class="form-header"
        justify="start"
        justify-sm="space-around"
        align="center"
      >
        <div cols="12" md="4" class="pa-2 pa-md-4">
          <div style="text-align: left; color: white">Firma:</div>
          <div>
            <strong>{{ firmaIDHeaderComp }}</strong>
          </div>
        </div>
        <div cols="4" class="pa-2 pa-md-4">
          <div style="text-align: left; color: white">Osoba:</div>
          <div>
            <strong>{{ osobaHeaderComp }}</strong>
          </div>
        </div>
        <div cols="4" class="pa-2 pa-md-4">
          <div style="text-align: left; color: white">E-mail:</div>
          <a :href="`mailto:${userEmail}`">{{ emailHeaderComp }}</a>
        </div>
      </v-row>

      <v-row no-gutters class="mb-6">
        <v-col cols="12" md="2">
          <v-text-field
            v-model="cisloObj"
            :counter="12"
            :rules="cisloObjRules"
            label="Číslo požadavku"
            class="px-3 pt-6"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="oddeleni"
            :items="oddeleniComboItems"
            :item-value="value"
            :rules="oddeleniRules"
            label="Oddělení"
            class="px-3 pt-6"
            outlined
            persistent-hint
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="nazevZar"
            :counter="48"
            :rules="nazevZarRules"
            label="Název zařízení"
            required
            class="px-3 pt-6"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="vyrCislo"
            :counter="32"
            :rules="vyrCisloRules"
            label="Výrobní číslo"
            required
            class="px-3"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="umisteniZar"
            :counter="28"
            :rules="umisteniZarRules"
            label="Umístění zařízení"
            required
            class="px-4"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="kodLokal"
            :counter="4"
            :rules="kodLokalRules"
            label="Kód lokality"
            required
            class="px-4"
            color--disabled="black"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-textarea
            v-model="popisZav"
            :rules="popisZavRules"
            label="Popis závady"
            required
            class="px-4"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- <v-select
          v-model="select"
          :items="items"
          :rules="[(v) => !!v || 'Item is required']"
          label="Item"
          required
        ></v-select> -->

      <!-- <v-checkbox
          v-model="checkbox"
          :rules="[(v) => !!v || 'You must agree to continue!']"
          label="Do you agree?"
          required
        ></v-checkbox> -->

      <v-row no-gutters align="center" justify="start" class="my-6">
        <v-col cols="9">
          <v-file-input
            v-model="file"
            accept=".jpg,.jpeg,.png,.pdf"
            class="mt-2"
            dense
            flat
            label="připojte přílohu .jpg, .jpeg, .png, .pdf"
            type="file"
          ></v-file-input>
        </v-col>
        <v-col col-sm="1">
          <div
            v-if="
              this.objPath != null &&
              this.objPath != '' &&
              this.theParamRecID != null
            "
            style="text-align: center"
          >
            <a :href="`${this.objPath}`" target="_blank"
              ><v-icon color="black">mdi-earth</v-icon></a
            >
          </div>
        </v-col>
        <v-col col-sm="2">
          <v-btn
            v-if="this.theParamRecID != null && fileLoadedComp"
            depressed
            v-on:click="uploadImage()"
            class=""
            >Připojit soubor</v-btn
          >
        </v-col>
      </v-row>

      <v-row no-gutters align="end" justify="space-between" class="my-6">
        <div class="tlacitka-box">
          <v-btn
            :disabled="formDisabled"
            color="error"
            class="mr-4"
            @click="reset"
          >
            Reset Form
          </v-btn>
          <v-btn
            :disabled="!valid || formDisabled"
            color="success"
            class="ma-4"
            @click="validate"
          >
            {{ btnTextComp }}
          </v-btn>
        </div>
      </v-row>

      <div
        v-if="theParamRecID != null && zakCislo != null"
        style="color: orange"
      >
        Číslo zakázky již bylo přiděleno. Požadavek nelze upravovat.
      </div>

      <!-- <v-btn color="warning" @click="resetValidation"> Reset Validation </v-btn> -->
    </v-form>
    <MsgBox ref="msgbox" style="position: fixed" />
  </div>
</template>


<script>
import axios from "axios";
import MsgBox from "@/components/MsgBox.vue";

export default {
  name: "MyForm",
  props: {
    msg: String,
  },
  components: {
    MsgBox,
  },
  created() {
    this.checkIfLoggedIn();
    this.theParamRecID = this.$route.params.recID;
    this.loadData();
  },
  computed: {
    firmaIDHeaderComp() {
      if (this.$route.params.recID != null) {
        return this.firmaID;
      } else return this.$store.getters.getFirmaID;
    },
    osobaHeaderComp() {
      if (this.theParamRecID != null) {
        return this.celeJmeno;
      } else return this.loggedJmenoPers + " " + this.loggedPrijmeniPers;
    },
    emailHeaderComp() {
      if (this.theParamRecID != null) {
        return this.userEmail;
      } else return this.loggedUserEmail;
    },
    fileLoadedComp() {
      if (this.file != null && this.file != "") {
        return true;
      }
      return false;
    },
    btnTextComp() {
      if (this.theParamRecID != null) {
        return "Update";
      } else return "Send";
    },
    myServerPath() {
      return this.$myServerPath;
    },
  },
  data: () => ({
    loggedUser: "",
    loggedUserName: "",
    loggedUserEmail: "",
    loggedFirmaID: "",
    loggedJmenoPers: "",
    loggedPrijmeniPers: "",

    formDisabled: false,
    //form data
    userName: "",
    userEmail: "",
    firmaID: "",
    celeJmeno: "",
    cisloObj: "",
    oddeleniComboItems: [
      { value: "SO", text: "Servisní oddělení" },
      { value: "RO", text: "Realizační oddělení" },
      { value: "MS", text: "Správa monitorovacího systému" },
    ],
    oddeleni: "",
    nazevZar: "",
    vyrCislo: "",
    umisteniZar: "",
    kodLokal: "",
    popisZav: "",
    objPath: "",
    file: "",
    zakCislo: null,

    valid: true,
    firmaIDRules: [
      (v) => !!v || "je požadováno",
      (v) => (v && v.length <= 50) || "max 50 znaků",
    ],
    userNameRules: [
      (v) => !!v || "je požadováno",
      (v) => (v && v.length <= 16) || "max 16 znaků",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail není platný",
    ],
    cisloObjRules: [(v) => (v && v.length <= 12) || "max 12 znaků"],
    nazevZarRules: [
      (v) => !!v || "je požadováno",
      (v) => (v && v.length <= 48) || "max 48 znaků",
    ],
    vyrCisloRules: [(v) => (v && v.length <= 32) || "max 32 znaků"],
    umisteniZarRules: [
      (v) => !!v || "je požadováno",
      (v) => (v && v.length <= 28) || "max 28 znaků",
    ],
    kodLokalRules: [
      (v) => !!v || "je požadováno",
      (v) => (v && v.length <= 4) || "max 4 znaků",
    ],
    popisZavRules: [(v) => !!v || "je požadováno"],
    oddeleniRules: [(v) => !!v || "je požadováno"],

    theParamRecID: "",

    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {
    checkIfLoggedIn() {
      if (this.$cookies.get("id") != null) {
        this.$store.commit("setUserID", this.$cookies.get("id"));
        this.$store.commit("setUserName", this.$cookies.get("name"));
        this.$store.commit("setUserEmail", this.$cookies.get("userEmail"));
        this.$store.commit("setFirmaID", this.$cookies.get("firmaID"));
        this.$store.commit("setJmenoPers", this.$cookies.get("jmenoPers"));
        this.$store.commit(
          "setPrijmeniPers",
          this.$cookies.get("prijmeniPers")
        );
        this.loadCredentials();
      } else this.$router.push({ name: "Login" });
    },
    loadCredentials() {
      this.loggedUser = this.$store.getters.getUserID;
      this.loggedUserName = this.$store.getters.getUserName;
      this.loggedUserEmail = this.$store.getters.getUserEmail;
      this.loggedFirmaID = this.$store.getters.getFirmaID;
      this.loggedJmenoPers = this.$store.getters.getJmenoPers;
      this.loggedPrijmeniPers = this.$store.getters.getPrijmeniPers;
    },
    async validate() {
      this.showProgres = true;
      await this.$refs.form.validate();
      if (this.valid) {
        if (this.theParamRecID == null) {
          let formData = new FormData();
          formData.append("file", this.file);
          formData.append("folder", this.$myServerPath + "/Objcts");
          formData.append("insertIntoTable", "SERVICE");
          formData.append(
            "insertIntoColumns",
            "`FIRMA ID`, `PERS ID`, `REPAIR ID`, `NAZEV SRV`, `HLAS SRV`, `STREDISKO ID`"
          );
          formData.append(
            "insertValues",
            `'${this.loggedFirmaID}', '${this.loggedUser}', '${this.cisloObj}', '${this.kodLokal}, ${this.umisteniZar}, ${this.nazevZar}, ${this.vyrCislo}', '${this.popisZav}', '${this.oddeleni}'`
          );

          formData.append("recID", this.theParamRecID);

          axios
            .post(this.$myServerPath + "/insertIntoSQL.php", formData, {
              header: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
              // console.log(response.data);
              if (response.data == "success") {
                var myMessage = "Požadavek byl odeslán";
                var myColor = this.$msg_color;
                this.sendEmail();
                this.reset();
              } else {
                var myMessage = "Požadavek se nepodařilo odeslat";
                var myColor = this.$warning_msg_color;
              }
              this.showMsgBox("Message", myMessage, {
                color: myColor,
                noconfirm: true,
                width: "300",
                height: "150",
              });
            });
        } else {
          axios
            .post(this.$myServerPath + "/updateRecord.php", {
              recID: this.theParamRecID,
              set:
                "`REPAIR ID` = " +
                `'${this.cisloObj}'` +
                ", `NAZEV SRV` = " +
                `'${this.kodLokal}` +
                ", " +
                `${this.umisteniZar}` +
                ", " +
                `${this.nazevZar}` +
                ", " +
                `${this.vyrCislo}'` +
                ", `HLAS SRV` = " +
                `'${this.popisZav}'`,
              // set: `konOsoba = '${this.userName}', konEmail = '${this.userEmail}', firmaID =  '${this.firmaID}', cisloObj = '${this.cisloObj}', nazevZarizeni = '${this.nazevZar}', vyrCislo = '${this.vyrCislo}', umisteniZarizeni = '${this.umisteniZar}', kodLokality = '${this.kodLokal}', popisZavady = '${this.popisZav}'`
            })
            .then((response) => {
              // console.log(response.data);
              if (response.data == "") {
                var myMessage = "Požadavek byl upraven";
                var myColor = this.$msg_color;
              } else {
                var myMessage = "Požadavek se nepodařilo upravit";
                var myColor = this.$warning_msg_color;
              }
              this.showMsgBox("Message", myMessage, {
                color: myColor,
                noconfirm: true,
                width: "300",
                height: "150",
              });
            });
        }
      }
      this.showProgres = false;
    },
    sendEmail() {
      switch (this.oddeleni) {
        case "SO":
          var sendCopyTo = this.$store.getters.getSO;
          break;
        case "RO":
          var sendCopyTo = this.$store.getters.getRO;
          break;
        case "MS":
          var sendCopyTo = this.$store.getters.getSMS;
      }
      axios
        .post(this.$myServerPath + "/sendMail.php", {
          serviceId: "",
          datVznik: "",
          recepient: this.loggedUserEmail,
          ccRecepient: `${sendCopyTo}`,
          emailType: "pozadavek-odeslan",
          cisloZakazky: null,
        })
        .then((response) => {
          //console.log(response.data);
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    loadData() {
      if (this.theParamRecID != null) {
        axios
          .post(this.$myServerPath + "/selectFromService.php", {
            userName: null,
            firmaID: null,
            recID: this.theParamRecID,
            whereFilter: null,
            stredisko: null,
          })
          .then((response) => {
            // console.log(response.data);
            if (response.data != false) {
              // console.log(response.data);
              this.userName = response.data[0]["PERS ID"];
              this.userEmail = response.data[0]["EMAIL PERS"];
              this.firmaID = response.data[0]["FIRMA ID"];

              var jmeno = response.data[0]["JMENO PERS"];
              var prijmeni = response.data[0]["PRIJMENI PERS"];
              if (jmeno === null) {
                jmeno = "";
              }
              if (prijmeni === null) {
                prijmeni = "";
              }
              this.celeJmeno = jmeno + " " + prijmeni;

              this.cisloObj = response.data[0]["REPAIR ID"];
              this.oddeleni = response.data[0]["STREDISKO ID"];

              let myArray = response.data[0]["NAZEV SRV"].split(",");
              this.kodLokal = myArray[0];
              this.umisteniZar = myArray[1];
              this.nazevZar = myArray[2];
              this.vyrCislo = myArray[3];

              this.popisZav = response.data[0]["HLAS SRV"];

              this.objPath = response.data[0]["PATHNAME"];

              this.zakCislo = response.data[0]["ZAKAZKA ID"];

              if (
                response.data[0]["ZAKAZKA ID"] != null
                //  ||  this.loggedFirmaID == "ENVItech Bohemia s.r.o."
              ) {
                this.formDisabled = true;
              } else this.formDisabled = false;
            }
          });
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("folder", this.$myServerPath + "/Objcts");
      formData.append("serviceID", this.theParamRecID);
      axios
        .post(this.$myServerPath + "/file_upload.php", formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log(response.data);
          this.file = "";
          this.loadData();
        });
    },

    async showMsgBox(nadpis, zprava, options) {
      if (await this.$refs.msgbox.open(nadpis, zprava, options)) {
      }
    },
  },
};
</script>

<style scope>
.form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 20px;
  border: 2px solid #42b983;
  border-radius: 5px;
}
.v-form {
  width: 1200px;
}
.form-header {
  background-color: lightgray;
  border-radius: 5px;
}
.v-divider {
  height: 2px !important;
  max-height: 2px !important;
  margin-bottom: 20px;
  border: 1px solid #42b983 !important;
}
.btn-box {
  display: flex;
  justify-content: end;
  align-items: end;
  height: 60px;
}
#app
  > div
  > form
  > button.mr-4.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.success {
  background-color: #8cc43f !important;
}
#app
  > div
  > form
  > button.mr-4.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.error {
  background-color: #2bc3fe !important;
}
.tlacitka-box {
  flex: 1 1 30%;
  text-align: right;
  align-self: center;
  padding: 10px 5px;
}
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}
.v-progress-circular {
  position: fixed;
  top: 50vh;
  left: 50%;
  margin: 1rem;
}
.v-select__selection--coma {
  color: #000 !important;
}
.theme--light.v-select .v-select__selection--disabled {
  color: #000 !important;
}
</style>
