<template>
  <div class="info_card">
    <v-card class="mx-auto card" width="400" height="auto">
      <h3>Jste příhlášen jako ...</h3>
      <div>
        Uživatel:<strong> {{ userName }} </strong>
      </div>
      <div>
        E-mail:<strong> {{ userEmail }} </strong>
      </div>
      <div>
        Firma: <strong>{{ loggedFirmaID }} </strong>
      </div>
      <div v-if="loggedFirmaID === 'ENVItech Bohemia s.r.o.'">
        <h5>Nastavení e-mail adres pro doručení požadavků na dané oddělení</h5>
        <div class="my-6">
          <div no-gutters v-for="(item, index) in myEmails" :key="index">
            <v-text-field
              v-model="item.email"
              :rules="emailRules"
              :label="item.oddeleni"
              :value="item.email"
              required
              outlined
              @change="saveEmails()"
            ></v-text-field>
          </div>
        </div>
      </div>

      <!-- <v-row no-gutters style="width: 230px">
        <v-col cols="4">oddeleni</v-col>
        <v-col cols="8">email</v-col>
      </v-row>

        <v-col cols="4">{{ item.oddeleni }}</v-col>
        <v-col cols="8">{{ item.email }}</v-col> -->

      <div class="ver">
        {{ this.$devVersion }}
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Info",
  created() {
    this.checkIfLoggedIn();
    this.getEmails();
  },
  data() {
    return {
      loggedUser: "",
      userName: "",
      userEmail: "",
      loggedFirmaID: "",
      records: "",
      myEmails: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail není platný",
      ],
    };
  },
  methods: {
    checkIfLoggedIn() {
      if (this.$cookies.get("id") != null) {
        this.$store.commit("setUserID", this.$cookies.get("id"));
        this.$store.commit("setUserName", this.$cookies.get("name"));
        this.$store.commit("setUserEmail", this.$cookies.get("userEmail"));
        this.$store.commit("setFirmaID", this.$cookies.get("firmaID"));
        this.loadCredentials();
      }
    },
    loadCredentials() {
      this.loggedUser = this.$store.getters.getUserID;
      this.userName = this.$store.getters.getUserName;
      this.userEmail = this.$store.getters.getUserEmail;
      this.loggedFirmaID = this.$store.getters.getFirmaID;
    },
    getEmails() {
      axios
        .get(this.$myServerPath + "/getEmailsJSON.php")
        .then((response) => {
          // console.log(response.data);
          this.myEmails = response.data.emails;
          this.$store.commit("setSO", this.myEmails[0].email);
          this.$store.commit("setRO", this.myEmails[1].email);
          this.$store.commit("setMS", this.myEmails[2].email);
        })
        .catch((error) => {
          this.error = "Error! Could not reach the API. " + error;
        });
    },
    saveEmails() {
      axios
        .post(this.$myServerPath + "/setEmailsJSON.php", {
          SOEmail: this.myEmails[0].email,
          ROEmail: this.myEmails[1].email,
          MSEmail: this.myEmails[2].email
        })
        .then((response) => {
          // console.log(response.data);
          this.getEmails();
        });
    },
  },
};
</script>

<style scoped>
.info_card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 10px;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px 40px;
}
h3 {
  align-self: center;
  margin-bottom: 20px;
  color: #2bc3fe;
}
h5 {
  margin-top: 50px;
}
.v-text-field {
  width: 320px;
}
.ver {
  margin-top: 30px;
  font-size: 12px;
}
@media (max-width: 726px) {
  .card {
    padding: 10px;
  }
}
</style>