<template>
  <div>
    <div id="nav">
      <a href="https://www.envitech-bohemia.cz/">
        <img class="logo" alt="logo" src="https://www.envitech-bohemia.cz/templates/210827103005/sys_logo.png"/>
        <!-- <img alt="logo" src="@/assets/logo.jpg"/> -->
        </a>
      <div class="menu">
        <router-link v-if="checkLoggedUserComp == 'Odhlásit'" to="/info"
          >Info</router-link
        >
        <router-link v-if="checkLoggedUserComp == 'Odhlásit'" to="/prehled"
          >Přehled servisních zásahů</router-link
        >
        <router-link v-if="checkLoggedUserComp == 'Odhlásit'" to="/novy"
          >Nový servisní zásah</router-link
        >
      </div>
      <router-link to="/login"
        ><div v-on:click="logOut()">{{ checkLoggedUserComp }}</div></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  created() {
    this.loggedUser = this.$store.getters.getUserID;
  },
  computed: {
    checkLoggedUserComp() {
      if (this.$store.getters.getUserID == "") {
        return "";
      } else return "Odhlásit";
    },
  },
  data() {
    return {
      loggedUser: "",
    };
  },
  methods: {
    logOut() {
      this.$store.commit("setUserID", "");
      this.$store.commit("setUserName", "");
      this.$store.commit("setUserEmail", "");
      this.$store.commit("setFirmaID", "");
      this.$store.commit("setJmenoPers", "");
      this.$store.commit("setPrijmeniPers", "");
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
    },
  },
};
</script>

<style scoped>
#nav {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  background-color: lightgray;
}
.menu {
  flex: 1 1 90%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
  background-color: lightgray;
}
a{
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
}
.router-link-exact-active {
  color: #42b983;
}
.logo {
  flex: 1 1 10%;
  height: 80px;
  text-align: left;
}
@media (max-width: 768px) {
  #nav {
    padding: 5px;
    height: 150px;
  }
  .menu {
    flex-direction: column;
    padding: 0;
  }
  .logo {
    display: none;
  }
  a{
    padding: 10px 5px;
  }
}
</style>