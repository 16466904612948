<template>
<v-app>
  <div id="app">
    <NavBar />
    <router-view/>
  </div>
</v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import axios from "axios"

export default {
  name: "App",
  components: {
    NavBar,
    // ProgressBar
  },
  created() {
    this.checkIfLoggedIn();
    this.getEmails();
  },
  data(){
    return{
    }
  },
  methods: {
    checkIfLoggedIn() {
      if (this.$cookies.get("id") != null) {
        this.$store.commit("setUserID", this.$cookies.get("id"));
        this.$router.push({ name: 'Novy' });
      } else this.$router.push({ name: 'Login' });
    },
      getEmails() {
      axios
        .get(this.$myServerPath + "/getEmailsJSON.php")
        .then((response) => {
          // console.log(response.data);
          this.myEmails = response.data.emails;
          this.$store.commit("setSO", this.myEmails[0].email);
          this.$store.commit("setRO", this.myEmails[1].email);
          this.$store.commit("setMS", this.myEmails[2].email);
        })
        .catch((error) => {
          this.error = "Error! Could not reach the API. " + error;
        });
    },
  }
};
</script>

<style lang="scss">
html {
  box-sizing: border-box;
}
*,
::after,
::before {
  box-sizing: inherit;
}
body {
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  max-width: 1460px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  background-image: linear-gradient(to top left, #2bc3fe 25%, #304d7e);
}
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a {
  font-weight: bold;
  color: #2bc3fe;
  text-decoration: none;
}
.green {
  color: #8cc43f;
}
.dark_green {
  color: #60a345;
}
.blue {
  color: #2bc3fe;
}
.dark_blue {
  color: #304d7e;
}

</style>
