<template>
  <div class="prehled">
    <v-progress-linear
      :class="toShowProgressBarComp ? 'showBar' : 'hideBar'"
      indeterminate
      height="18"
      ><div style="color: white">Loading ...</div></v-progress-linear
    >
    <v-row
      no-gutters
      v-if="loggedFirmaID == 'ENVItech Bohemia s.r.o.'"
      class="searchBlock"
    >
      <v-col cols="12" sm="7" md="6" lg="5" class="py-0">
        <v-autocomplete
          v-model="selectedFirma"
          :items="seznamFirem"
          outlined
          dense
          clearable
          label="Firma"
          item-text="FIRMA ID"
          item-value="FIRMA ID"
          append-icon="mdi-magnify"
          @change="getData"
          filled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="5" md="4" lg="3" class="py-0">
        <v-select
          v-model="selectedStredisko"
          :items="strediska"
          outlined
          dense
          clearable
          label="Středisko"
          append-icon="mdi-magnify"
          @change="getData"
          filled
          class="pa-0 ml-0 ml-md-4"
        ></v-select>
      </v-col>
    </v-row>

    <div>
      <table>
        <tr class="bold">
          <th>ID</th>
          <th>FIRMA</th>
          <th>OSOBA</th>
          <th>ČISLO POŽADAVKU</th>
          <th>
            <div>
              <v-icon @click="orderNumbers('ZAKAZKA ID', 'asc')"
                >mdi-sort-variant</v-icon
              ><v-icon @click="orderNumbers('ZAKAZKA ID', 'desc')"
                >mdi-sort-reverse-variant</v-icon
              >
            </div>
            ZAKAZKA
          </th>
          <th>NAZEV</th>
          <th>
            <div>
              <v-icon @click="orderDates('DAT VZNIK', 'asc')"
                >mdi-sort-variant</v-icon
              ><v-icon @click="orderDates('DAT VZNIK', 'desc')"
                >mdi-sort-reverse-variant</v-icon
              >
            </div>
            VYTVOŘENO
          </th>
          <th>
            PŘIJATO
            <!-- <div>
              <v-icon @click="orderDates('DAT SRV', 'asc')"
                >mdi-sort-variant</v-icon
              ><v-icon @click="orderDates('DAT SRV', 'desc')"
                >mdi-sort-reverse-variant</v-icon
              >
            </div> -->
          </th>
          <th>
            TERMÍN
            <!-- <div>
              <v-icon @click="orderDates('TERMIN', 'asc')"
                >mdi-sort-variant</v-icon
              ><v-icon @click="orderDates('TERMIN', 'desc')"
                >mdi-sort-reverse-variant</v-icon
              >
            </div> -->
          </th>
          <th>
            DOKONČENO
            <!-- <div>
              <v-icon @click="orderDates('DAT DODANI', 'asc')"
                >mdi-sort-variant</v-icon
              ><v-icon @click="orderDates('DAT DODANI', 'desc')"
                >mdi-sort-reverse-variant</v-icon
              >
            </div> -->
          </th>
          <th>ZÁRUKA</th>
          <th style="min-width: 20px">OBJECT</th>
        </tr>
        <tr
          v-for="(item, index) in records"
          v-bind:key="index"
          :class="{
            unviewed:
              item.Viewed === '0' &&
              loggedFirmaID === 'ENVItech Bohemia s.r.o.',
          }"
        >
          <td @click="showCard(index, 0)">{{ item["SERVICE ID"] }}</td>
          <td @click="showCard(index, 0)">{{ item["FIRMA ID"] }}</td>
          <td @click="showCard(index, 0)">{{ getFullName(index) }}</td>
          <td @click="showCard(index, 0)">{{ item["REPAIR ID"] }}</td>
          <td @click="showCard(index, 1)">{{ item["ZAKAZKA ID"] }}</td>
          <td @click="showCard(index, 0)">{{ getNazev(index) }}</td>
          <td @click="showCard(index, 0)" style="min-width: 83px">
            {{ getDateFormat(index, "DAT VZNIK") }}
          </td>
          <td @click="showCard(index)" style="min-width: 83px">
            {{ getDateFormat(index, "DAT TAKEOVER") }}
          </td>
          <td @click="showCard(index)" style="min-width: 83px">
            {{ getDateFormat(index, "DAT SRV") }}
          </td>
          <td @click="showCard(index)" style="min-width: 83px">
            {{ getDateFormat(index, "DAT REAL") }}
          </td>
          <td @click="showCard(index)" style="text-align: center">
            {{ getZaruka(item) }}
          </td>

          <td
            v-if="checkObjPath(item)"
            style="text-align: center; background-color: white"
          >
            <a :href="`${item.PATHNAME}`" target="_blank"
              ><v-icon color="black">mdi-earth</v-icon></a
            >
          </td>
        </tr>
      </table>
    </div>
    <v-dialog v-model="dialog" max-width="600" v-if="areRecordsComp">
      <v-card>
        <div class="close_icon" @click="closeDialog()"></div>
        <v-card-title class="text-h5">
          {{ records[index]["SERVICE ID"] }}
        </v-card-title>

        <!-- <v-select
          v-model="cisloZakazky"
          :items="cisloZakazkyComboItems"
          :item-text="(item) => `${item['ZAKAZKA ID']} - ${item['NAZEV ZAK']}`"
          :item-value="(item) => `${item['ZAKAZKA ID']}`"
          label="Přiřaďte číslo zakázky"
          cols="3"
          class="px-4 pt-6"
          hide-selected
          outlined
        ></v-select> -->

         <v-autocomplete
          v-model="cisloZakazky"
          :items="cisloZakazkyComboItems"
          :item-text="(item) => `${item['ZAKAZKA ID']} - ${item['NAZEV ZAK']}`"
          :item-value="(item) => `${item['ZAKAZKA ID']}`"
          outlined
          dense
          clearable
          label="Přiřaďte číslo zakázky"
          append-icon="mdi-magnify"
          filled
          class="px-4 pt-6"
        ></v-autocomplete>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            :disabled="cisloZakazky === '' || cisloZakazky === null"
            text
            @click="updateRecord(index)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MsgBox ref="msgbox" style="position: fixed" />
  </div>
</template>

<script>
import axios from "axios";
import MsgBox from "@/components/MsgBox.vue";

export default {
  name: "Prehled",
  props: {
    msg: String,
  },
  components: {
    MsgBox,
  },
  beforeMount() {
    this.$store.commit("setShowProgress", true);
  },
  created() {
    this.checkIfLoggedIn();
    this.selectedFirma = this.$store.getters.getSelectedFirma;
    this.selectedStredisko = this.$store.getters.getSelectedStredisko;
    this.getData();
  },
  computed: {
    myServerPath() {
      return this.$myServerPath;
    },
    btnUpdateDisabledComp() {
      if (this.cisloZakazky == "") {
        return false;
      } else {
        return true;
      }
    },
    areRecordsComp() {
      if (this.records.length > 0) {
        return true;
      } else return false;
    },
    toShowProgressBarComp() {
      if (this.$store.getters.getShowProgress === true) {
        return true;
      } else return false;
    },
  },
  data() {
    return {
      loggedUser: "",
      loggedUserName: "",
      loggedUserEmail: "",
      loggedFirmaID: "",
      loggedJmenoPers: "",
      loggedPrijmeniPers: "",
      celeJmeno: "",
      emailPers: "",
      records: [],
      seznamFirem: [],
      dialog: false,
      index: 0,
      cisloZakazky: "",
      selectedFirma: "",
      cisloZakazkyComboItems: [],
      selectedStredisko: "",
      strediska: ["SO", "RO", "MS"],
    };
  },
  methods: {
    checkIfLoggedIn() {
      if (this.$cookies.get("id") != null) {
        this.$store.commit("setUserID", this.$cookies.get("id"));
        this.$store.commit("setUserName", this.$cookies.get("name"));
        this.$store.commit("setUserEmail", this.$cookies.get("userEmail"));
        this.$store.commit("setFirmaID", this.$cookies.get("firmaID"));
        this.$store.commit("setJmenoPers", this.$cookies.get("jmenoPers"));
        this.$store.commit(
          "setPrijmeniPers",
          this.$cookies.get("prijmeniPers")
        );
        this.loadCredentials();
      } else this.$router.push({ name: "Login" });
    },
    loadCredentials() {
      this.loggedUser = this.$store.getters.getUserID;
      this.loggedUserName = this.$store.getters.getUserName;
      this.loggedUserEmail = this.$store.getters.getUserEmail;
      this.loggedFirmaID = this.$store.getters.getFirmaID;
      this.loggedJmenoPers = this.$store.getters.getJmenoPers;
      this.loggedPrijmeniPers = this.$store.getters.getPrijmeniPers;
    },

    getData() {
      this.$store.commit("setShowProgress", true);
      this.$store.commit("setSelectedFirma", this.selectedFirma);
      this.$store.commit("setSelectedStredisko", this.selectedStredisko);
      this.records = [];
      if (this.selectedFirma != null) {
        var myWhereFilter = this.selectedFirma;
      } else myWhereFilter = "";
      axios
        .post(this.$myServerPath + "/selectFromService.php", {
          firmaID: this.loggedFirmaID,
          recID: "",
          whereFilter: `${myWhereFilter}`,
          stredisko: this.selectedStredisko,
        })
        .then((response) => {
          if (response.data != "array_empty") {
            // console.log(response.data);
            this.seznamFirem = response.data.map((row) => row["FIRMA ID"]);
            // console.log(this.seznamFirem);
            this.records = response.data.sort(
              (a, b) => b["SERVICE ID"] - a["SERVICE ID"]
            );
          } else {
            this.showMsgBox("Message", "Nebyly nalezeny žádné položky.", {
              color: this.$msg_color,
              noconfirm: true,
              width: 300,
              height: 150,
            });
            //console.log(response.data);
          }
          this.$store.commit("setShowProgress", false);
        });
    },
    getFullName(index) {
      var jmeno = this.records[index]["JMENO PERS"];
      var prijmeni = this.records[index]["PRIJMENI PERS"];
      if (jmeno === null) {
        jmeno = "";
      }
      if (prijmeni === null) {
        prijmeni = "";
      }
      return jmeno + " " + prijmeni;
    },
    getNazev(index) {
      let myArray = this.records[index]["NAZEV SRV"].split(",");
      return myArray[2];
    },
    getDateFormat(i, date) {
      if (this.records[i][date] != null) {
        return this.records[i][date].substr(0, 10);
      } else return "-";
    },
    getSaveServ(i, date) {
      if (this.records[i][date] == 0) {
        return "";
      } else return "";
    },
    checkObjPath(item) {
      if (item.PATHNAME == "" || item.PATHNAME == null) {
        return false;
      } else return true;
    },
    getZaruka(item) {
      let myStr = item["ZAKAZKA ID"];
      if (myStr != null && myStr != "") {
        if (myStr.startsWith("Z")) {
          return "Ano";
        } else return "Ne";
      } else return "-";
    },
    orderNumbers(prop, order) {
      if (order == "asc") {
        this.records.sort(this.propComparatorNumbers_Asc(prop));
      }
      if (order == "desc") {
        this.records.sort(this.propComparatorNumbers_Desc(prop));
      }
    },
    propComparatorNumbers_Asc(prop) {
      return function (a, b) {
        if (a[prop] < b[prop]) {
          return 1;
        }
        if (a[prop] > b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    propComparatorNumbers_Desc(prop) {
      return function (a, b) {
        if (a[prop] < b[prop]) {
          return -1;
        }
        if (a[prop] > b[prop]) {
          return 1;
        }
        return 0;
      };
    },

    orderDates(prop, order) {
      if (order == "asc") {
        this.records.sort(this.propComparator_Asc(prop));
      }
      if (order == "desc") {
        this.records.sort(this.propComparator_Desc(prop));
      }
    },
    propComparator_Asc(prop) {
      return function (a, b) {
        if (
          new Date(a[prop].replace(" ", "T")) <
          new Date(b[prop].replace(" ", "T"))
        ) {
          return 1;
        }
        if (
          new Date(a[prop].replace(" ", "T")) >
          new Date(b[prop].replace(" ", "T"))
        ) {
          return -1;
        }
        return 0;
      };
    },
    propComparator_Desc(prop) {
      return function (a, b) {
        if (
          new Date(a[prop].replace(" ", "T")) <
          new Date(b[prop].replace(" ", "T"))
        ) {
          return -1;
        }
        if (
          new Date(a[prop].replace(" ", "T")) >
          new Date(b[prop].replace(" ", "T"))
        ) {
          return 1;
        }
        return 0;
      };
    },
    showCard(index, key) {
      if (this.loggedFirmaID == "ENVItech Bohemia s.r.o." && key == 1) {
        this.dialog = !this.dialog;
        this.index = index;
        axios
          .post(this.$myServerPath + "/selectFromSQL.php", {
            selectInTable: "`ZAKAZKA`",
            selectColumns: "`ZAKAZKA ID`, `NAZEV ZAK`",
            selectWhere:
              "`FIRMA ID` = " + `'${this.records[index]["FIRMA ID"]}'`,
          })
          .then((response) => {
            console.log(response.data);
            this.cisloZakazkyComboItems = response.data;
          });
      } else {
        this.$router.push({
          name: "Novy",
          params: { recID: this.records[index]["SERVICE ID"] },
        });
      }
      if (this.loggedFirmaID == "ENVItech Bohemia s.r.o."){
        this.setViewed(index);
      }
    },
    setViewed(index) {
      axios
        .post(this.$myServerPath + "/setViewed.php", {
          serviceIdToMarkAsViewed: this.records[index]["SERVICE ID"],
        })
        .then((response) => {
          //console.log(response.data);
        });
    },
    updateRecord(index) {
      axios
        .post(this.$myServerPath + "/updateCisloZakazky.php", {
          formDataID: this.records[index]["SERVICE ID"],
          cisloZakazky: this.cisloZakazky,
          objectID: this.records[index]["OBJECT ID"],
        })
        .then((response) => {
          //console.log(response.data);
        });
      this.sendEmail(index, this.cisloZakazky);
      this.cisloZakazky = "";
      this.dialog = false;
    },
    sendEmail(index, zakazCisl) {
      axios
        .post(this.$myServerPath + "/sendMail.php", {
          recepient: this.records[index]["EMAIL PERS"],
          serviceId: this.records[index]["SERVICE ID"],
          datVznik: this.records[index]["DAT VZNIK"],
          ccRecepient: "",
          emailType: "pozadavek-zpracovan",
          cisloZakazky: `${zakazCisl}`,
        })
        .then((response) => {
          //console.log(response.data);
          setTimeout(this.getData(), 5000);
        });
    },
    closeDialog() {
      this.dialog = false;
      this.cisloZakazky = "";
      this.cisloZakazkyComboItems = "";
    },
    async showMsgBox(nadpis, zprava, options) {
      if (await this.$refs.msgbox.open(nadpis, zprava, options)) {
      }
    },
  },
};
</script>
<style>
.prehled {
  padding: 0 20px 20px 20px;
  overflow: auto;
}
.searchBlock {
  display: flex;
  justify-content: start;
  align-items: start;
}
table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}
tr:nth-child(even) {
  background-color: #fef5ee;
}
th,
td {
  border-bottom: 1px solid lightgray;
  padding: 8px;
  text-align: left;
  vertical-align: bottom;
  cursor: pointer;
}
th {
  /* min-width: 90px; */
}
.v-icon {
  cursor: pointer;
}
.v-list {
  text-align: left;
}
.unviewed {
  font-weight: 800;
}
.v-progress-linear {
  margin: 0 0 10px 0;
  padding: 0;
}
.showBar {
  visibility: visible;
}
.hideBar {
  visibility: hidden;
}
.close_icon {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 31px;
  color: black;
  cursor: pointer;
}
.close_icon:before {
  content: "×";
}
@media (max-width: 768px) {
  .prehled {
    justify-content: start;
    margin: 0;
  }
  .searchBlock {
    flex-direction: column;
  }
}
</style>