<template>
  <div class="login_card">
    <v-card class="mx-auto" width="400" height="235" elevation="24">
      <v-card-text class="pb-0">
        <v-text-field
          label="User name"
          v-model="userName"
          class="ma-4 input-white"
          hide-details="auto"
          color="white"
        ></v-text-field>
        <v-text-field
          label="Password"
          v-model="pswd"
          :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="() => (value = !value)"
          :type="value ? 'password' : 'text'"
          class="ma-4"
          color="white"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="pt-0 px-4">
        <img class="login-logo" alt="logo" src="@/assets/logo.png" />
        <v-btn
          :disabled="btnDisabledComp"
          absolute
          right
          text
          color="white"
          @click="login"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
    <MsgBox ref="msgbox" style="position: fixed" />
  </div>
</template>

<script>
import MsgBox from "@/components/MsgBox.vue";
import axios from "axios";

export default {
  name: "Login",
  components: {
    MsgBox,
  },
  created() {},
  computed: {
    btnDisabledComp() {
      if (this.userName == "" || this.pswd == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      loggedUser: "",
      userName: "",
      pswd: "",
      value: true,
    };
  },
  methods: {
    login() {
      axios
        .post(this.$myServerPath + "/login.php", {
          userName: this.userName,
          pswd: this.pswd,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data != false) {
            this.$store.commit("setUserID", response.data[0]["PERS ID"]);
            this.$store.commit("setUserName", response.data[0]["ENAME PERS"]);
            this.$store.commit("setUserEmail", response.data[0]["EMAIL PERS"]);
            this.$store.commit("setFirmaID", response.data[0]["FIRMA ID"]);
            this.$store.commit("setJmenoPers", response.data[0]["JMENO PERS"]);
            this.$store.commit(
              "setPrijmeniPers",
              response.data[0]["PRIJMENI PERS"]
            );
            this.$cookies.set("id", response.data[0]["PERS ID"], "1d");
            this.$cookies.set("name", response.data[0]["ENAME PERS"], "1d");
            this.$cookies.set(
              "userEmail",
              response.data[0]["EMAIL PERS"],
              "1d"
            );
            this.$cookies.set("firmaID", response.data[0]["FIRMA ID"], "1d");
            this.$cookies.set(
              "jmenoPers",
              response.data[0]["JMENO PERS"],
              "1d"
            );
            this.$cookies.set(
              "prijmeniPers",
              response.data[0]["PRIJMENI PERS"],
              "1d"
            );

            this.userName = "";
            this.pswd = "";

            this.$router.push({ name: "Prehled" });
          } else {
            var soEmail = this.$store.getters.getSO;
            var roEmail = this.$store.getters.getRO;
            var msEmail = this.$store.getters.getMS;
            this.showMsgBox(
              "Message",
              "Nesprávně zadané údaje. Pro zaslání nového hesla kontaktujte ENVItech Bohemia s.r.o.. Servisní oddělení: " +
                soEmail +
                ", Realizační oddělení: " +
                roEmail +
                ", Měření: " +
                msEmail,
              {
                color: "",
                noconfirm: true,
                width: 340,
                height: 250,
              }
            );
            this.pswd = "";
          }
        });
    },
    // MsgBox
    async showMsgBox(nadpis, zprava, options) {
      if (await this.$refs.msgbox.open(nadpis, zprava, options)) {
      }
    },
  },
};
</script>

<style scoped>
.login_card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 10px;
}
.login-logo {
  visibility: hidden;
  height: 50px;
}
#app > div.login_card > div {
  background-image: linear-gradient(to top right, #2bc3fe 25%, #304d7e);
}
::v-deep .theme--light.v-input input,
.theme--light.v-input textarea {
  color: white !important;
}

@media (max-width: 576px) {
  .login-logo {
    visibility: visible;
    align-self: start;
    padding: 5px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>