import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const headers = { Accept: "application/json"};

export default new Vuex.Store({
  state: {
    userID: '',
    userName: '',
    userEmail: '',
    firmaID: '',
    jmenoPers: '',
    prijmeniPers: '',
    SO: '',
    RO: '',
    MS: '',
    showProgress: false,
    selectedFirma: '',
    selectedStredisko: ''
  },
  mutations: { //syncronous
    
    setUserID(state, payload) {
      state.userID = payload;
    },
    setUserName(state, payload) {
      state.userName = payload;
    },
    setFirmaID(state, payload) {
      state.firmaID = payload;
    },
    setUserEmail(state, payload) {
      state.userEmail = payload;
    },
    setJmenoPers(state, payload) {
      state.jmenoPers = payload;
    },
    setPrijmeniPers(state, payload) {
      state.prijmeniPers = payload;
    },
    setSO(state, payload) {
      state.SO = payload;
    },
    setRO(state, payload) {
      state.RO = payload;
    },
    setMS(state, payload) {
      state.MS = payload;
    },
    setShowProgress(state, payload) {
      state.showProgress = payload;
    },
    setSelectedFirma(state, payload) {
      state.selectedFirma = payload;
    },
    setSelectedStredisko(state, payload) {
      state.selectedStredisko = payload;
    }
  },
  actions: { //asyncronous
    //async setMessage(state) {
      //const joke = await fetch(url, { headers });
      //const j = await joke.json();
      //state.commit("setMessage", j.joke);
    //}
  },
  modules:{

  },
  getters: {
    getUserID: state => state.userID,
    getUserName: state => state.userName,
    getFirmaID: state => state.firmaID,
    getUserEmail: state => state.userEmail,
    getJmenoPers: state => state.jmenoPers,
    getPrijmeniPers: state => state.prijmeniPers,
    getSO: state => state.SO,
    getRO: state => state.RO,
    getMS: state => state.MS,
    getShowProgress: state => state.showProgress,
    getSelectedFirma: state => state.selectedFirma,
    getSelectedStredisko: state => state.selectedStredisko
  },
   

});
